<template>
  <div class="management">
    <div class="content-Box mincss">
      <div class="items-box" v-for="items in 4" :key="items">
        <div class="flexBox">
          <div class="imgCircle">
            <div style="margin-bottom:-4px;margin-top:4px;">导入</div>
            简历
          </div>
          <div class="timer" style="margin-top:6px;">
            <div>
              <span>{{ 11321 }}</span>
            </div>
            <div class="content-area">
              {{
                '初面反馈：王琳2018年2月到2019年4月做建材类销售，主要为电销和面销结合的形式，在小组业绩一直保持中上，后来因为加班太多离职，主要是公司活动比较多，经常加班到11点多，感觉特别辛苦。最近一份工作是在蘑菇街做客服工作，感觉不太适合自己，想先做回销售，沟通中感觉比较积极，能接受比较合理的加班。'
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="divider"></div>
    </div>
    <a-dropdown
      :trigger="['click']"
      style="position:absolute;right:0px;top:0px;"
    >
      <span class="ant-dropdown-link">
        <a-icon type="ordered-list" /> 更多操作</span
      >
      <a-menu slot="overlay">
        <a-menu-item key="0">
          <span @click="drawerRecordsFn('添加详情')">添加详情</span>
        </a-menu-item>
        <a-menu-item key="1">
          <span @click="comfrimModel('提醒评价')">提醒面试官评价</span>
        </a-menu-item>
        <!-- <a-menu-divider /> -->
        <a-menu-item key="2">
          <span @click="drawerRecordsFn('面试官评价')">面试官评价</span>
        </a-menu-item>
        <a-menu-item key="3">
          <span @click="drawerRecordsFn">面试取消</span>
        </a-menu-item>
        <a-menu-item key="4">
          <span @click="comfrimModel('提醒面试')">提醒面试</span>
        </a-menu-item>
      </a-menu>
    </a-dropdown>

    <a-drawer
      :title="controlType"
      width="340"
      placement="right"
      :closable="false"
      @close="onClose"
      :visible="controlType"
    >
      <div v-if="controlType == '添加详情'">
        <a-form style="padding-bottom:50px;">
          <a-form-item v-bind="formItemLayout" label="姓名">
            <a-input type="text" v-model="CVname" placeholder="请输入姓名" />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="性别">
            <a-radio-group @change="onChange" v-model="CVsex">
              <a-radio :value="1">女</a-radio>
              <a-radio :value="2">男</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="邮箱">
            <a-auto-complete
              :dataSource="CVemail"
              @change="handleChange"
              placeholder="请输入邮箱"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="手机">
            <a-input
              type="text"
              v-model="CVphone"
              placeholder="请输入手机号码"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="面试岗位">
            <a-select v-model="CVpost" placeholder="请选择岗位">
              <a-select-option
                v-for="item in CVpost_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="现居住地">
            <a-input
              type="text"
              v-model="CVaddress"
              placeholder="请输入现居住地"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="简历来源">
            <a-select v-model="CVsource" placeholder="请选择简历来源">
              <a-select-option
                v-for="item in CVsource_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>

          <a-form-item v-bind="formItemLayout" label="期望薪资">
            <a-select v-model="CVsalary" placeholder="请选择期望薪资">
              <a-select-option
                v-for="item in CVsalary_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="学历">
            <a-select v-model="CVeducation" placeholder="请选择学历">
              <a-select-option
                v-for="item in CVeducation_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="籍贯">
            <a-select v-model="CVnativePlace" placeholder="请选择籍贯">
              <a-select-option
                v-for="item in CVnativePlace_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="上传照片">
            <a-upload
              :fileList="imgfileList"
              :remove="ImghandleRemove"
              :beforeUpload="imgbeforeUpload"
            >
              <a-button> <a-icon type="upload" />上传照片 </a-button>
            </a-upload>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="上传简历">
            <a-upload
              :fileList="CVfileList"
              :remove="CVhandleRemove"
              :beforeUpload="CVbeforeUpload"
            >
              <a-button> <a-icon type="upload" /> 上传简历 </a-button>
            </a-upload>
          </a-form-item>
        </a-form>
      </div>
      <!-- 面试官评价 -->
      <div v-else>
        <a-form style="padding-bottom:50px;">
          <a-form-item label="面试岗位">
            <a-select v-model="CVpost" placeholder="请选择岗位">
              <a-select-option
                v-for="item in CVpost_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>

          <a-form-item label="简历来源">
            <a-select v-model="CVsource" placeholder="请选择简历来源">
              <a-select-option
                v-for="item in CVsource_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="" style="margin-top: 24px;">
            <a-upload-dragger
              name="file"
              @change="fileDrawerChange"
              :beforeUpload="fileBeforeUpload"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">上传文件或将文件拖入</p>
              <!--  :multiple="true" action="https://www.mocky.io/v2/5cc8019d300000980a055e76" -->
              <p class="ant-upload-hint">
                支持文件格式：.doc,.docx,.txt,.rtf,.pdf,.html,.wps,等文本格式简历全部都支持解析。
              </p>
            </a-upload-dragger>
          </a-form-item>
        </a-form>
      </div>
      <div class="drawer-btns">
        <a-button type="default" @click="onClose" class="drawer-btn"
          >取 消</a-button
        ><a-button class="drawer-btn" type="primary">确 定</a-button>
      </div>
    </a-drawer>

    <div></div>
  </div>
</template>
<script>
export default {
  name: 'management',
  data () {
    return {
      formItemLayout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
      },
      controlType: false,
      id: '' //简历id
    }
  },
  created () {
    this.id = this.$route.query.id
    console.log(this.id)
    this.$axios.get(9950, {}, res => {
      console.log(res.data)
    })
    this.$axios.get(9946, { Id: this.id }, res => {
      if (!res.data) return false
      console.log(res.data)
      if (res.data.code == 1) {
      } else {
        this.$message.error(res.data.msg)
      }
    })
  },
  methods: {
    drawerRecordsFn (type) {
      this.controlType = type
    },
    onClose () {
      //简历导入 引入的侧边栏隐藏
      this.controlType = false
    },
    comfrimModel (type) {
      this.$confirm({
        title: `${type}`,
        content:
          'When clicked the OK button, this dialog will be closed after 1 second',
        onOk () {
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000)
          }).catch(() => console.log('Oops errors!'))
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style lang="less" scoped>
.management {
  position: relative;
  font-size: 14px;
  color: #333333;
  .flexBox {
    display: -webkit-flex;
    display: flex;
  }

  .timer {
    color: #999999;
  }

  .content-Box {
    position: relative;
    margin: 10px auto;
    .items-box {
      margin-bottom: 1px;
      .imgCircle {
        min-width: 40px;
        width: 40px;
        margin-right: 8px;
        position: relative;
        height: 40px;
        border-radius: 50%;
        z-index: 100;
        font-size: 12px;
        background-color: #46c35f;
        color: #ffffff;
        text-align: center;
      }
      .content-area {
        color: #000000;
        font-weight: 500;
        background-color: #ffffff;
        border-radius: 4px;
        padding: 06px 1px;
      }
    }
    .divider {
      position: absolute;
      top: 0px;
      left: 19px;
      border-left: 3px solid #d3ebff;
      height: 100%;
      // z-index: -10;
    }
  }
}
.ant-form-item {
  margin-bottom: 14px;
}
</style>
